import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent"
import RelatedProjects from "../components/CaseStudiesDetails/RelatedProjects"

const CaseDetails = () => {
  const pageTitle = "Case Study - Entropia Investment & Shares"
  const activePageText = "Case Study  Entropia Investment & Shares"

  // Main Content Area
  const type =
    "1 Admin Website + 1 Customer Facing Website + React Native App (iOS/Android)"
  const title = ""
  const body1 = ""
  const body2 = ""
  const body3 = ""
  const results = ""

  // Side bar content area
  const clientName = ""
  const clientLink = ""
  const clientName2 = ""
  const clientLink2 = ""
  const location = ""
  const technologies = ""
  const completionDate = ""
  const hrefName = ""
  const href = ""

  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={pageTitle}
        homePageText="Home"
        homePageUrl="/"
        activePageText={activePageText}
      />
      <CaseStudiesDetailsContent
        type={type}
        title={title}
        body1={body1}
        body2={body2}
        body3={body3}
        results={results}
        clientName={clientName}
        clientLink={clientLink}
        clientName2={clientName2}
        clientLink2={clientLink2}
        location={location}
        technologies={technologies}
        completionDate={completionDate}
        hrefName={hrefName}
        href={href}
      />
      {/* <RelatedProjects /> */}
      <Footer />
    </Layout>
  )
}

export default CaseDetails
